namespace $ {
	
	$mol_style_define( $hyoo_page, {
		
		background: {
			size: [ 'cover' ],
			position: 'center',
		},

		Menu: {
			'@media': {
				'print': {
					display: 'none',
				},
			},
		},
		
		Edit: {
			'@media': {
				'print': {
					display: 'none',
				},
			},
		},
		
	} )
	
}
